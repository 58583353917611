import request from '../Http'
import {HttpMethodEnum} from '../../utils/libs';

/**
 * @namespace API
 * @description API 方法集合
 */
const API = {
    /**
     * @function providers
     * @description 取得登入GCIP
     * @param {Object} data - 註冊數據
     * @returns {Promise} 註冊請求的 Promise 對象
     */
    providers(data) {
        return request({
            url: '/v1/auth/providers',
            method: HttpMethodEnum.GET,
            data
        });
    },

    /**
     * @function login
     * @description 用戶登入
     * @param {Object} data - 登入數據
     * @returns {Promise} 登入請求的 Promise 對象
     */
    login(data) {
        return request({
            url: '/v1/auth/login',
            method: HttpMethodEnum.POST,
            data
        });
    },
    /**
     * @function refreshToken
     * @description 刷新登入權限token
     * @returns {Promise} 登入請求的 Promise 對象
     */
    refreshToken() {
        return request({
            url: '/v1/auth/refresh',
            method: HttpMethodEnum.POST
        })
    },

    /**
     * @function logout
     * @description 用戶登出
     * @param {Object} data - 登出數據
     * @returns {Promise} 登出請求的 Promise 對象
     */
    logout(data) {
        return request({
            url: '/v1/user/logout',
            method: HttpMethodEnum.POST,
            data
        });
    },

    /**
     * @function getProfileInfo
     * @description 獲取用戶資料
     * @param {Object} params - 查詢參數
     * @returns {Promise} 獲取用戶資料請求的 Promise 對象
     */
    getProfileInfo(params) {
        return request({
            url: '/v1/users/me',
            method: HttpMethodEnum.GET,
            params
        });
    },

    /**
     * @function updateProfile
     * @description 編輯用戶資料
     * @param {Object} data - 查詢參數
     * @returns {Promise} 獲取用戶資料請求的 Promise 對象
     */
    updateProfile(data) {
        return request({
            url: '/v1/users/me',
            method: HttpMethodEnum.PATCH,
            data
        });
    },

    /**
     * @function getKnowledgeBase
     * @description 獲取知識庫信息
     * @param {string} orgId - 組織 ID
     * @param {string} projectId - 項目 ID
     * @returns {Promise} 代表知識庫數據的 Promise 對象
     */
    getKnowledgeBase(orgId, projectId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/knowbase`,
            method: HttpMethodEnum.GET,
        });
    },

    /**
     * @function downloadDocument
     * @description 下載知識庫已上傳的檔案
     * @param {string} orgId - 組織 ID
     * @param {string} projectId - 項目 ID
     * @param {string} knowbaseId
     * @param {string} documentId - 文件 ID
     * @returns {Promise} 代表知識庫數據的 Promise 對象
     */
    downloadDocument(orgId, projectId, knowbaseId, documentId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/knowbase/documents/${documentId}/download`,
            method: HttpMethodEnum.GET,
            responseType: 'blob'  // 重要：需要設置 responseType 為 'blob'
        });
    },

    /**
     * @function uploadDocument
     * @description 知識庫上傳
     * @param {string} orgId - 組織 ID
     * @param {string} projectId - 項目 ID
     * @param {string} knowbaseId - 知識庫 ID
     * @param formData
     * @returns {Promise} 代表知識庫數據的 Promise 對象
     */
    uploadDocument: async (orgId, projectId, knowbaseId, formData) => {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/knowbase/${knowbaseId}/documents`,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    /**
     * @function publishKnowledgeBase
     * @description 發布知識庫更新
     * @param {string} orgId - 組織 ID
     * @param {string} projectId - 項目 ID
     * @returns {Promise} 代表知識庫數據的 Promise 對象
     */
    publishKnowledgeBase: async (orgId, projectId) => {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/knowbase/publish`,
            method: 'PUT',
            timeout: 30000 // 設定 30 秒 timeout
        });
    },

    /**
     * @function modifyPassword
     * @description 修改用戶資料
     * @param {Object} data - 修改的用戶資料
     * @returns {Promise} 修改用戶資料請求的 Promise 對象
     */
    modifyPassword(data) {
        return request({
            url: '/v1/auth/password/',
            method: HttpMethodEnum.PUT,
            data
        });
    },

    /**
     * @function getDashboardOverview
     * @description 取得儀表板資訊
     * @returns {Promise} 修改用戶資料請求的 Promise 對象
     * @param orgId
     * @param projectId
     * @param timezone
     */
    getDashboardOverview(orgId, projectId, timezone) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/dashboard/overview`,
            method: HttpMethodEnum.GET,
            params: { timezone }
        });
    },

    /**
     * @function getChartModels
     * @description 取得費用資訊
     * @returns {Promise} 修改用戶資料請求的 Promise 對象
     * @param orgId
     * @param projectId
     * @param params
     */
    getChartModels(orgId, projectId, params) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/dashboard/chart-models`,
            method: HttpMethodEnum.GET,
            params
        });
    },

    /**
     * @function getChartTraces
     * @description 取得發問數資訊
     * @returns {Promise} 修改用戶資料請求的 Promise 對象
     * @param orgId
     * @param projectId
     * @param params
     */
    getChartTraces(orgId, projectId, params) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/dashboard/chart-traces`,
            method: HttpMethodEnum.GET,
            params
        });
    },

    /**
     * @function getQATraces
     * @description 取得發問數資訊
     * @returns {Promise} 修改用戶資料請求的 Promise 對象
     * @param orgId
     */
    getTraces({ orgId, projectId, from_time, to_time, limit, page }) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/traces`,
            method: HttpMethodEnum.GET,
            params: { from_time, to_time, limit, page }
        });
    },

    /**
     * @function getSessionTraces
     * @description 獲取特定用戶的對話記錄
     * @param {Object} params - 請求參數
     * @returns {Promise} 獲取用戶對話記錄的 Promise 對象
     */
    getSessionTraces({ orgId, projectId, session_id }) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/sessions/${session_id}`,
            method: HttpMethodEnum.GET
        });
    },

    /**
     * @function exportTraces
     * @description 匯出問答追蹤數據
     * @param {Object} params - 請求參數
     * @returns {Promise} 匯出問答追蹤數據的 Promise 對象
     */
    exportTraces({ orgId, projectId, from_time, to_time, timezone }) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/traces/export`,
            method: HttpMethodEnum.GET,
            params: { from_time, to_time, timezone },
            responseType: 'blob', // 設置響應類型為 blob
        });
    },

    /**
     * @function getChatBotSettings
     * @description 獲取ChatBot的設置
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 專案ID
     * @returns {Promise} 獲取ChatBot設置的Promise對象
     */
    getChatBot(orgId, projectId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot`,
            method: HttpMethodEnum.GET
        });
    },

    /**
     * 獲取聊天機器人用語設定
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @returns {Promise} 獲取設定的Promise對象
     */
    getChatbotPhrases(orgId, projectId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/phrases`,
            method: 'GET'
        });
    },

    /**
     * 更新聊天機器人用語設定
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @param {Object} data - 更新的數據
     * @returns {Promise} 更新設定的Promise對象
     */
    updateChatbotPhrases(orgId, projectId, data) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/phrases`,
            method: 'PUT',
            data
        });
    },

    /**
     * 獲取提示問題列表
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @returns {Promise} 獲取提示問題的Promise對象
     */
    getStarterPrompts(orgId, projectId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/starter_prompts`,
            method: 'GET'
        });
    },

    /**
     * 更新提示問題列表
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @param {Object} data - 更新的數據
     * @returns {Promise} 更新提示問題的Promise對象
     */
    updateStarterPrompts(orgId, projectId, data) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/starter_prompts`,
            method: 'PUT',
            data
        });
    },

    /**
     * 獲取速率限制設定
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @returns {Promise} 獲取速率限制設定的Promise對象
     */
    getRateLimit(orgId, projectId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/rate_limit`,
            method: 'GET'
        });
    },

    /**
     * 更新速率限制設定
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @param {Object} data - 更新的數據
     * @returns {Promise} 更新速率限制設定的Promise對象
     */
    updateRateLimit(orgId, projectId, data) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/rate_limit`,
            method: 'PUT',
            data
        });
    },

    /**
     * 獲取運作狀態設定
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @returns {Promise} 獲取運作狀態設定的Promise對象
     */
    getActivation(orgId, projectId) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/activation`,
            method: 'GET'
        });
    },

    /**
     * 更新運作狀態設定
     * @param {string} orgId - 組織ID
     * @param {string} projectId - 項目ID
     * @param {Object} data - 更新的數據
     * @returns {Promise} 更新運作狀態設定的Promise對象
     */
    updateActivation(orgId, projectId, data) {
        return request({
            url: `/v1/organizations/${orgId}/projects/${projectId}/chatbot_settings/activation`,
            method: 'PUT',
            data
        });
    }
};

export default API;
