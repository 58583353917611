import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {
    Box,
    Typography,
    TextField,
    Snackbar,
    Alert, Button, CircularProgress
} from "@mui/material";
import {ContentCopy as ContentCopyIcon} from '@mui/icons-material';
import API from "../../api/v1";
import {useChatbot} from "../../contexts/ChatbotContext";

const LanguageSettingsTab = forwardRef(({ orgId, projectId }, ref) => {
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const { setWelcomeMessage: setGlobalWelcomeMessage } = useChatbot();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [fallbackMessage, setFallbackMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // 驗證字段
    const [errors, setErrors] = useState({
        welcomeMessage: false,
        fallbackMessage: false
    });

    const generateScript = (message) => {
        return `
        theme: {
            chatWindow: {
                welcomeMessage: "${message}"
            }
        }`
    };

    // 獲取初始數據
    useEffect(() => {
        fetchPhrases();
    }, [orgId, projectId]);

    const fetchPhrases = async () => {
        try {
            const response = await API.getChatbotPhrases(orgId, projectId);
            if (response.code === 0 && response.data) {
                setWelcomeMessage(response.data.welcomeMessage || '');
                setFallbackMessage(response.data.fallbackMessage || '');
            } else {
                throw new Error(response.message || '獲取設定失敗');
            }
        } catch (err) {
            console.error('獲取設定失敗:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // 暴露獲取數據的方法給父組件
    useImperativeHandle(ref, () => ({
        validateFields,
        async getLanguageData() {
            // 先進行驗證
            const isValid = validateFields();

            // 只有在驗證通過時才進行API呼叫
            if (isValid) {
                const data = {
                    welcomeMessage,
                    fallbackMessage
                };
                try {
                    const response = await API.updateChatbotPhrases(orgId, projectId, data);
                    if (response.code === 0) {
                        setGlobalWelcomeMessage(welcomeMessage);
                        return data;
                    } else {
                        throw new Error(response.message || '更新失敗');
                    }
                } catch (error) {
                    throw error;
                }
            }
            // 如果驗證不通過，返回null
            return null;
        }
    }));

    const handleCopyCode = async () => {
        try {
            const scriptContent = generateScript(welcomeMessage);
            await navigator.clipboard.writeText(scriptContent);
            setOpenSnackbar(true);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const validateFields = () => {
        const newErrors = {
            welcomeMessage: !welcomeMessage.trim(),
            fallbackMessage: !fallbackMessage.trim()
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress sx={{ color: '#CBA660' }} />
            </Box>
        );
    }
    if (error) {
        return (
            <Box sx={{p: 3}}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ textAlign: 'left'}}>
            <Typography variant="h6" sx={{ mb: 2 }}>歡迎語</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <TextField
                    fullWidth
                    value={welcomeMessage}
                    onChange={(e) => {
                        setWelcomeMessage(e.target.value);
                        setErrors(prev => ({...prev, welcomeMessage: false}));
                    }}
                    error={errors.welcomeMessage}
                    helperText={errors.welcomeMessage ? "請輸入內容" : ""}
                    sx={{
                        width: '100%',
                        maxWidth: '564px',
                    }}
                />
                <Button
                    onClick={handleCopyCode}
                    variant="outlined"
                    startIcon={<ContentCopyIcon />}
                    size="small"
                    sx={{
                        width: '110px',
                        backgroundColor: '#FFFFFF',
                        borderColor: '#CBA660',
                        color: '#CBA660',
                        whiteSpace: 'nowrap',
                        flexShrink: 0,
                        ml: 2,
                        '&:hover': { backgroundColor: '#CBA660', color: '#FFFFFF', borderColor: '#CBA660', },
                    }}
                >
                    複製程式碼
                </Button>
            </Box>
            <Typography variant="caption" color="textSecondary" sx={{ mb: 4, display: 'block' }}>
                使用者在開啟客服時看到的預設訊息 <br/>
                注意：此發布針對測試 Robot，請複製程式碼並更新至正式 Robot 才會生效
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>無法回答或超出範圍問題回覆</Typography>
            <TextField
                fullWidth
                value={fallbackMessage}
                onChange={(e) => {
                    setFallbackMessage(e.target.value);
                    setErrors(prev => ({...prev, fallbackMessage: false}));
                }}
                error={errors.fallbackMessage}
                helperText={errors.fallbackMessage ? "請輸入內容" : ""}
                sx={{
                    width: '100%',
                    maxWidth: '564px',
                }}
            />
            <Typography variant="caption" color="textSecondary" sx={{ mb: 4, display: 'block' }}>
                未輸入狀態，Robot 將使用用戶的語言禮貌地表示無法回答
                <br />輸入指定內容，Robot將一律使用此制式訊息回應
            </Typography>

            {/* 複製成功提示 */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    程式碼已複製到剪貼板
                </Alert>
            </Snackbar>
        </Box>
    );
});

export default LanguageSettingsTab;