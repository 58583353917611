import Layout from "../../Layout/Layout";
import {Alert, Box, Button, Card, CircularProgress, Stack, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import FileUploadTable from "../../components/KnowledgeBase/FileUploadTable";
import API from "../../api/v1";
import {formatDate} from "../../utils/dateUtils";
import useUserInfo from "../../hooks/userProfile";
import {BubbleChat} from 'flowise-embed-react'
import {useChatbot} from "../../contexts/ChatbotContext";

const KnowledgeBase = () => {
    const [knowledgeBaseData, setKnowledgeBaseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [publishing, setPublishing] = useState(false);
    const {userInfo, loading: userInfoLoading} = useUserInfo();
    const [chatBotSettings, setChatBotSettings] = useState(null);
    const [testChatBotLoading, setTestChatBotLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'info'});
    const [chatFlowId, setChatFlowId] = useState(null);
    const [apiHost, setApiHost] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const { welcomeMessage } = useChatbot();
    const handleDocumentsUpdate = useCallback((updateFn) => {
        setKnowledgeBaseData(prevData => ({
            ...prevData,
            documents: updateFn(prevData.documents)
        }));
    }, []);

    const fetchKnowledgeBase = useCallback(async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }

        setLoading(true);
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.getKnowledgeBase(orgId, projectId);
            if (response.code === 0) {
                setKnowledgeBaseData(response.data);
                if(response.data.canPublish) {
                    setShowAlert(true);
                }
            } else {
                throw new Error(response.message || '獲取知識庫數據失敗');
            }
        } catch (err) {
            console.error('獲取知識庫時出錯:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [userInfo]);

    const handlePublish = async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }

        setPublishing(true);
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.publishKnowledgeBase(orgId, projectId);
            if (response.code === 0) {
                // 發布成功，刷新知識庫數據
                await fetchKnowledgeBase();
                // 可以添加一個成功提示
                setShowAlert(false);
            } else {
                throw new Error(response.message || '發布知識庫失敗');
            }
        } catch (err) {
            console.error('發布知識庫時出錯:', err);
            setError(err.message);
            // 可以添加一個錯誤提示
        } finally {
            setPublishing(false);
        }
    };

    const fetchChatBotSettings = useCallback(async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }

        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.getChatBot(orgId, projectId);
            if (response.code === 0) {
                setChatBotSettings(response.data);
            } else {
                throw new Error(response.message || '獲取ChatBot設置失敗');
            }
        } catch (err) {
            console.error('獲取ChatBot設置時出錯:', err);
            setError(err.message);
            setSnackbar({open: true, message: '獲取ChatBot設置失敗', severity: 'error'});
        }
    }, [userInfo]);

    // 取得聊天機器人設定
    const handleChatBotSetting = () => {
        const newChatFlowId = chatBotSettings.stage.flowise.chatflowId;
        const newApiHost = chatBotSettings.stage.flowise.apiHost;

        setChatFlowId(newChatFlowId);
        setApiHost(newApiHost);
        setTestChatBotLoading(true)
    }

    useEffect(() => {
        fetchKnowledgeBase().then(r => {});
        fetchChatBotSettings().then(r => {})
    }, [fetchKnowledgeBase, fetchChatBotSettings]);

    if (userInfoLoading || loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) return <Typography color="error">錯誤: {error}</Typography>;

    return (
        <Layout>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, maxWidth: '956px'}}>
                <Typography variant={'h4'} sx={{textAlign: 'left', mb: 5, mt: 2}}>知識庫</Typography>
                <Box width={'100%'} mb={8} sx={{position: 'relative'}}>
                    {showAlert && (
                        <Alert
                            severity="info"
                            onClose={() => setShowAlert(false)}
                            sx={{position: 'absolute', top: '-80px', left: '50%', transform: 'translateX(-50%)', width: '100%',mb: 2}}
                        >
                            資料已上傳，正在更新知識庫，更新完成後會看到[發布]按鈕已可點擊，建議先使用測試機器人確認效果後，再按下[發布]，更新到正式機器人
                        </Alert>
                    )}
                    <Stack direction={'row'} spacing={2}>
                        <Card elevation={2} sx={{
                            width: '100%',
                            maxWidth: '466px',
                            height: '214px',
                            mr: 2,
                            pt: 6,
                            boxSizing: 'border-box'
                        }}>
                            <Typography variant={'h5'} fontWeight={'bold'} mb={2}>正式站</Typography>
                            <Typography variant={'body2'} fontWeight={'bold'}>知識庫最後更新時間</Typography>
                            <Typography variant={'body2'} mb={2}>
                                {formatDate(knowledgeBaseData?.lastProdUpdatedAt, "尚未更新知識庫")}
                            </Typography>
                        </Card>
                        <Card elevation={2} sx={{ width: '100%', maxWidth: '466px', height: '214px', pt: 6, boxSizing: 'border-box' }}>
                            <Typography variant={'h5'} fontWeight={'bold'} mb={2}>測試站</Typography>
                            <Typography variant={'body2'} fontWeight={'bold'}>知識庫最後更新時間</Typography>
                            <Typography variant={'body2'} mb={2}>
                                {formatDate(knowledgeBaseData?.lastStageUpdatedAt, "尚未更新知識庫")}
                            </Typography>
                            <Box>
                                <Button
                                    variant="contained"
                                    size={'small'}
                                    sx={{backgroundColor: '#CBA660', '&:hover': {backgroundColor: '#B8935C'}, mr: 1}}
                                    onClick={handleChatBotSetting}
                                >
                                    測試站 chatbot</Button>
                                <Button
                                    variant="contained"
                                    size={'small'}
                                    sx={{backgroundColor: '#CBA660', '&:hover': {backgroundColor: '#B8935C'}}}
                                    disabled={!knowledgeBaseData?.canPublish || publishing}
                                    onClick={handlePublish}>
                                    {publishing ? <CircularProgress size={24}/> : '發佈'}
                                </Button>
                            </Box>
                        </Card>
                    </Stack>
                </Box>
                <Box sx={{pt: 2, boxSizing: 'border-box'}}>
                    <Typography variant={'h5'} textAlign={'left'} fontWeight={'bold'} mb={1}>必要知識源</Typography>
                    <Typography variant={'body1'} textAlign={'left'} mb={5}>此為必要內容，無法刪除，一個項目只有一個 CSV 檔，上傳新版後智庫將自動取代舊檔</Typography>
                    <FileUploadTable
                        documents={knowledgeBaseData?.documents || []}
                        orgId={userInfo.organizations[0].id}
                        projectId={userInfo.organizations[0].projects[0].id}
                        knowbaseId={knowledgeBaseData?.id}
                        onDocumentsUpdate={handleDocumentsUpdate}
                        onRefreshData={fetchKnowledgeBase}
                    />
                </Box>
            </Box>
            {testChatBotLoading && (
                <BubbleChat chatflowid={chatFlowId} apiHost={apiHost}
                            theme={{
                                button: {
                                    backgroundColor: "#CBA660",
                                    right: 20,
                                    bottom: 20,
                                    size: 48, // small | medium | large | number
                                    dragAndDrop: true,
                                    iconColor: "white",
                                    customIconSrc: 'https://raw.githubusercontent.com/ipevolabs/web-assets/5701c2720db8683b29c6d24a4a92fc1ef77ccde3/img_logo_esky_profile.svg',
                                    autoWindowOpen: {
                                        autoOpen: false, //parameter to control automatic window opening
                                        openDelay: 2, // Optional parameter for delay time in seconds
                                        autoOpenOnMobile: false, //parameter to control automatic window opening in mobile
                                    },
                                },
                                tooltip: {
                                    showTooltip: true,
                                    tooltipMessage: '需要幫助嗎？👋',
                                    tooltipBackgroundColor: 'black',
                                    tooltipTextColor: 'white',
                                    tooltipFontSize: 16,
                                },
                                chatWindow: {
                                    showTitle: true,
                                    // 標題名稱
                                    title: '義享AI客服',
                                    // 標題logo
                                    titleAvatarSrc: '',
                                    showAgentMessages: true,
                                    welcomeMessage: welcomeMessage, // 使用共享狀態
                                    // errorMessage: 'This is a custom error message',
                                    backgroundColor: "#ffffff",
                                    backgroundImage: 'enter image path or link', // If set, this will overlap the background color of the chat window.
                                    height: 600,
                                    width: 400,
                                    fontSize: 16,
                                    //starterPrompts: ['What is a bot?', 'Who are you?'], // It overrides the starter prompts set by the chat flow passed
                                    starterPromptFontSize: 15,
                                    clearChatOnReload: false, // If set to true, the chat will be cleared when the page reloads.
                                    botMessage: {
                                        backgroundColor: "#f7f8ff",
                                        textColor: "#303235",
                                        showAvatar: true,
                                        avatarSrc: "https://raw.githubusercontent.com/ipevolabs/web-assets/5701c2720db8683b29c6d24a4a92fc1ef77ccde3/img_logo_esky_chat.svg",
                                    },
                                    userMessage: {
                                        backgroundColor: "#CBA660",
                                        textColor: "#ffffff",
                                        showAvatar: true,
                                        avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
                                    },
                                    textInput: {
                                        placeholder: '請輸入您的問題',
                                        backgroundColor: '#ffffff',
                                        textColor: '#303235',
                                        sendButtonColor: '#CBA660',
                                        maxChars: 100,
                                        maxCharsWarningMessage: '抱歉，您的問題超過100字的限制，請重新輸入，謝謝。',
                                        autoFocus: true, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
                                        sendMessageSound: false,
                                        // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
                                        receiveMessageSound: false,
                                        // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true.
                                    },
                                    feedback: {
                                        color: '#303235',
                                    },
                                    footer: {
                                        textColor: '#303235',
                                        text: 'Powered by',
                                        company: 'IPEVO',
                                        companyLink: 'https://vurbo.ai',
                                    }
                                }
                            }}
                />
            )}
        </Layout>
    )
}

export default KnowledgeBase