import React, {useRef, useState} from 'react';
import Layout from "../../Layout/Layout";
import {
    Box,
    Typography,
    Tabs,
    Tab,
    Button,
    Alert,
    Snackbar
} from "@mui/material";
import {ContentCopy as ContentCopyIcon} from '@mui/icons-material';
import PromptQuestionsTab from "../../components/AIServiceSetting/PromptQuestionTab";
import LanguageSettingsTab from "../../components/AIServiceSetting/LanguageSettingsTab";
import UsageLimitTab from "../../components/AIServiceSetting/UsageLimitTab";
import OperationStatusTab from "../../components/AIServiceSetting/OperationStatusTab";
import useUserInfo from "../../hooks/userProfile";
import {getLocalStorage} from "../../utils/localStorage";

// TabPanel組件用於顯示tab內容 (保持不變)
const TabPanel = ({children, value, index}) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const AIServiceSetting = () => {
    const [tabValue, setTabValue] = useState(0);
    const [saving, setSaving] = useState(false);
    const {userInfo} = useUserInfo();
    const languageSettingsRef = useRef();
    const promptQuestionsRef = useRef();
    const usageLimitRef = useRef();
    const operationStatusRef = useRef();
    const userInfoString = getLocalStorage('userInfo');
    const localStorageUserInfo = JSON.parse(userInfoString);
    const organizationId = localStorageUserInfo?.organizations?.[0]?.id;
    const projetctsId = localStorageUserInfo?.organizations?.[0]?.projects?.[0]?.id;
    const projectId =  userInfo?.organizations[0].projects[0].id || projetctsId;
    const orgId = userInfo?.organizations[0].id || organizationId;
    console.log(projectId)

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // 處理函數保持不變...
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // 驗證當前tab的資料
    const validateCurrentTab = () => {
        try {
            switch (tabValue) {
                case 0: // 設定用語
                    if (!languageSettingsRef.current) return false;
                    return languageSettingsRef.current.validateFields();

                case 1: // 提示問題
                    if (!promptQuestionsRef.current) return false;
                    return promptQuestionsRef.current.validateFields();

                case 2: // 用量限制
                    if (!usageLimitRef.current) return false;
                    return usageLimitRef.current.validateFields();

                case 3: // 運作狀態
                    if (!operationStatusRef.current) return false;
                    return operationStatusRef.current.validateFields();

                default:
                    return false;
            }
        } catch (error) {
            console.error('驗證失敗:', error);
            return false;
        }
    };

    const handleSave = async () => {
        // 先驗證當前 tab
        if (!validateCurrentTab()) {
            setSnackbar({
                open: true,
                message: '請填寫所有必填欄位',
                severity: 'error'
            });
            return;
        }
        setSaving(true);
        try {
            switch (tabValue) {
                case 0: // 設定用語
                    await handleLanguageSettingsSave();
                    break;
                case 1: // 提示問題
                    await handlePromptQuestionsSave();
                    break;
                case 2: // 用量限制
                    await handleUsageLimitSave();
                    break;
                case 3: // 運作狀態
                    await handleOperationStatusSave();
                    break;
                default:
                    break;
            }

            // 顯示成功訊息
            setSnackbar({
                open: true,
                message: '儲存成功！',
                severity: 'success'
            });
        } catch (error) {
            // 顯示錯誤訊息
            setSnackbar({
                open: true,
                message: `儲存失敗: ${error.message}`,
                severity: 'error'
            });
        } finally {
            setSaving(false);
        }
    };

    // 修改各個 Tab 的儲存處理函數
    const handleLanguageSettingsSave = async () => {
        if (!languageSettingsRef.current) throw new Error('元件未初始化');
        const languageData = await languageSettingsRef.current.getLanguageData();
        if (!languageData) throw new Error('請填寫所有必填欄位');
        console.log('語言設定數據:', JSON.stringify(languageData, null, 2));
        // await API.saveLanguageSettings(languageData);
    };

    const handlePromptQuestionsSave = async () => {
        if (!promptQuestionsRef.current) throw new Error('元件未初始化');
        const questionsData = await promptQuestionsRef.current.getQuestionsData();
        if (!questionsData) throw new Error('請填寫所有必填欄位');
        console.log('提示問題數據:', JSON.stringify(questionsData, null, 2));
        // await API.savePromptQuestions(questionsData);
    };

    const handleUsageLimitSave = async () => {
        if (!usageLimitRef.current) throw new Error('元件未初始化');
        const usageLimitData = await usageLimitRef.current.getUsageLimitData();
        if (!usageLimitData) throw new Error('請填寫所有必填欄位');
        console.log('用量限制設定:', JSON.stringify(usageLimitData, null, 2));
        // await API.saveUsageLimit(usageLimitData);
    };

    const handleOperationStatusSave = async () => {
        if (!operationStatusRef.current) throw new Error('元件未初始化');
        const operationData = await operationStatusRef.current.getOperationStatusData();
        if (!operationData) throw new Error('請填寫所有必填欄位');
        console.log('運作狀態設定:', JSON.stringify(operationData, null, 2));
        // await API.saveOperationStatus(operationData);
    };

    // 獲取當前Tab的儲存按鈕文字
    const getSaveButtonText = () => {
        if (saving) return '儲存中...';

        switch (tabValue) {
            default:
                return '儲存並發布';
        }
    };

    return (
        <Layout>
            <Box sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
                <Typography variant="h4" sx={{ textAlign: 'left', mb: 5, mt: 2 }}>
                    AI客服設定
                </Typography>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTab-root': {
                                color: 'rgba(0, 0, 0, 0.6)',
                                '&.Mui-selected': {
                                    color: '#CBA660'
                                }
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#CBA660'
                            }
                        }}
                    >
                        <Tab label="設定用語" />
                        <Tab label="提示問題" />
                        <Tab label="用量限制" />
                        <Tab label="運作狀態" />
                    </Tabs>
                </Box>

                {/* 設定用語 Tab */}
                <TabPanel value={tabValue} index={0}>
                    <LanguageSettingsTab
                        ref={languageSettingsRef}
                        orgId={orgId}
                        projectId={projectId}
                    />
                </TabPanel>

                {/* 提示問題 Tab */}
                <TabPanel value={tabValue} index={1}>
                    <PromptQuestionsTab
                        ref={promptQuestionsRef}
                        orgId={orgId}
                        projectId={projectId}
                    />
                </TabPanel>

                {/* 用量限制 Tab */}
                <TabPanel value={tabValue} index={2}>
                    <UsageLimitTab
                        ref={usageLimitRef}
                        orgId={orgId}
                        projectId={projectId}
                    />
                </TabPanel>

                {/* 運作狀態 Tab - 新增完整內容 */}
                <TabPanel value={tabValue} index={3}>
                    <OperationStatusTab
                        ref={operationStatusRef}
                        orgId={orgId}
                        projectId={projectId}
                    />
                </TabPanel>

                {/* 底部儲存按鈕 */}
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={handleSave}
                        disabled={saving}
                        variant="contained"
                        startIcon={<ContentCopyIcon />}
                        sx={{
                            backgroundColor: '#CBA660',
                            '&:hover': { backgroundColor: '#B8935C' }
                        }}
                    >
                        {getSaveButtonText()}
                    </Button>
                </Box>
            </Box>

            {/* 儲存並發布提示訊息 */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Layout>
    );
};

export default AIServiceSetting;
