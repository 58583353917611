import Layout from "../../Layout/Layout";
import {Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import {DataGrid} from "@mui/x-data-grid";
import InspectionDialog from "../../components/QADetails/InspectionDialog";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useNavigate, useParams} from "react-router-dom";
import API from "../../api/v1";
import useUserInfo from "../../hooks/userProfile";

const QADetails = () => {
    const {userInfo} = useUserInfo();
    const [dateRange, setDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]); // 日期範圍，預設為最近30天
    const [periodSelect, setPeriodSelect] = useState(30); // 預定義時間範圍選擇，預設為30天
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [qaData, setQaData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();

    const fetchData = useCallback(async (newPage, newPageSize) => {
        setLoading(true);
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;            // 假設 API 對象中有一個 getTraces 方法
            const response = await API.getTraces({
                orgId,
                projectId,
                from_time: dateRange[0].format(),
                to_time: dateRange[1].format(),
                limit: newPageSize,
                page: newPage + 1 // API 使用 1-based indexing
            });

            if (response.code === 0 && response.data) {
                setQaData(response.data.traces.map(trace => ({
                    id: trace.id,
                    date: dayjs(trace.timestamp).format('YYYY/MM/DD'),
                    time: dayjs(trace.timestamp).format('HH:mm:ss'),
                    userId: trace.sessionId,
                    question: trace.input,
                    answer: trace.output,
                    latency: trace.latency,
                    totalCostTWD: trace.totalCostTWD,
                    totalCostUSD: trace.totalCostUSD,
                })));
                setTotalItems(response.data.meta.totalItems);
                // 如果 API 返回的分頁信息與當前不一致，更新 paginationModel
                if (response.data.meta.page - 1 !== paginationModel.page || response.data.meta.limit !== paginationModel.pageSize) {
                    setPaginationModel({
                        page: response.data.meta.page - 1,
                        pageSize: response.data.meta.limit,
                    });
                }
            } else {
                console.error('API error:', response.message);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setLoading(false);
        }
    }, [userInfo, dateRange]);

    useEffect(() => {
        fetchData(paginationModel.page, paginationModel.pageSize).then(r => {});
    }, [fetchData]);

    const handleViewClick = (params) => {
        setSelectedRow(params.row);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleExport = async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.exportTraces({
                orgId,
                projectId,
                from_time: dateRange[0].format(),
                to_time: dateRange[1].format(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                responseType: 'blob'
            });

            let blobData;
            if (response instanceof Blob) {
                blobData = response;
            } else if (response.data instanceof Blob) {
                blobData = response.data;
            } else {
                throw new Error('響應中沒有找到 Blob 數據');
            }
            let filename = `traces_${dateRange[0].format('YYYY-MM-DD')}_${dateRange[1].format('YYYY-MM-DD')}.csv`; // 默認文件名

            // 嘗試從 headers 獲取文件名
            if (response.headers && response.headers['content-disposition']) {
                const contentDisposition = response.headers['content-disposition'];
                const filenameMatch = contentDisposition.match(/filename=(.+)/i);
                if (filenameMatch && filenameMatch.length > 1) {
                    filename = filenameMatch[1].replace(/["']/g, '').trim();
                }
            }

            // 使用 Blob 創建 URL
            const url = window.URL.createObjectURL(blobData);

            // 創建一個臨時的 a 標籤來觸發下載
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setSnackbarMessage('文件下載成功');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Export failed:', error);
            setSnackbarMessage(`導出失敗: ${error.message}`);
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handlePaginationModelChange = (newPaginationModel) => {
        // 如果 pageSize 發生變化，重置到第一頁
        if (newPaginationModel.pageSize !== paginationModel.pageSize) {
            fetchData(0, newPaginationModel.pageSize);
        } else {
            fetchData(newPaginationModel.page, newPaginationModel.pageSize);
        }
        // 立即更新 paginationModel 狀態，以確保 UI 立即反映變化
        setPaginationModel(newPaginationModel);
    };

    // 根據日期範圍確定適當的時間單位
    const determineTemporalUnit = useCallback((start, end) => {
        const diffDays = end.diff(start, 'day');
        if (diffDays <= 1) return 'hour';
        if (diffDays <= 61) return 'day';
        return 'month';
    }, []);
    // 更新日期範圍和時間單位
    const updateDateRangeAndUnit = useCallback((start, end) => {
        setDateRange([start, end]);
    }, [determineTemporalUnit]);

    // 處理日期範圍選擇器變更
    const handleDateRangeChange = (newDateRange) => {
        if (newDateRange[0] && newDateRange[1]) {
            setDateRange(newDateRange);
            fetchData(0, paginationModel.pageSize).then(r => {});
        }
    };
    // 處理預定義時間範圍選擇變更
    const handlePeriodSelectChange = (event) => {
        const days = event.target.value;
        setPeriodSelect(days);
        const end = dayjs();
        const start = end.subtract(days, 'day');
        updateDateRangeAndUnit(start, end);
        fetchData(0, paginationModel.pageSize).then(r => {});
    };

    const columns = [
        { field: 'date', headerName: '日期', width: 110 },
        { field: 'time', headerName: '時間', width: 110 },
        { field: 'userId', headerName: '使用者識別 ID', width: 320,
        renderCell: (params) => (
            <Button
                variant={'outlined'}
                size="small"
                onClick={() => navigate(`/qa-user/${params.value}`)}
                sx={{
                    backgroundColor: '#FFFFFF',
                    borderColor: '#CBA660',
                    color: '#CBA660',
                    '&:hover': { backgroundColor: '#CBA660', color: '#FFFFFF' },
                }}
            >
                {params.value}
            </Button>
        )},
        { field: 'question', headerName: '提問', flex: 1 },
        { field: 'answer', headerName: '回答', flex: 1 },
        {
            field: 'view',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleViewClick(params)}
                    sx={{
                        backgroundColor: '#CBA660',
                        '&:hover': { backgroundColor: '#B8935C' }
                    }}
                >
                    檢視
                </Button>
            ),
        },
    ];

    return (
        <Layout>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3}}>
                <Typography variant={'h4'} sx={{textAlign: 'left', mb: 5, mt: 2}}>問答明細</Typography>

                {/*日期範圍搜尋*/}
                <Card elevation={1}
                      raised={true}
                      sx={{
                          width: '100%',
                          maxWidth: '956px'
                      }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '32px',
                            mb: 3
                    }}>
                        <Box mr={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    value={dateRange}
                                    onChange={handleDateRangeChange}
                                    localeText={{ start: '開始日期', end: '結束日期' }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box mr={2}>
                            <FormControl>
                                <InputLabel id="period-select-label">期間</InputLabel>
                                <Select
                                    labelId="period-select-label"
                                    id="period-select"
                                    value={periodSelect}
                                    label="期間"
                                    onChange={handlePeriodSelectChange}
                                    sx={{width: '220px'}}
                                    variant={"outlined"}>
                                    <MenuItem value={1}>當日</MenuItem>
                                    <MenuItem value={7}>一周</MenuItem>
                                    <MenuItem value={30}>一個月</MenuItem>
                                    <MenuItem value={90}>三個月</MenuItem>
                                    <MenuItem value={180}>六個月</MenuItem>
                                    <MenuItem value={365}>一年</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button variant="contained" onClick={fetchData} sx={{backgroundColor: '#CBA660','&:hover': { backgroundColor: '#B8935C' }}}>搜尋</Button>
                    </Box>
                    {/*問答表格*/}
                    <Box width={'100%'}>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                            <Box width={20}></Box>
                            <Button
                                variant={"text"}
                                startIcon={<FileDownloadIcon />}
                                onClick={handleExport}
                                sx={{
                                    color: '#CBA660',
                                    '&:hover': { color: '#B8935C' },
                                    textTransform: 'none', // 確保按鈕文字不會全部大寫
                                    mr:1
                                }}
                            >
                                匯出
                            </Button>
                        </Box>
                        <DataGrid
                            rows={qaData}
                            rowHeight={72}
                            columns={columns}
                            disableRowSelectionOnClick
                            pagination
                            paginationMode="server"
                            rowCount={totalItems}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[10, 20, 30, 50]}
                            loading={loading}
                            disableSelectionOnClick
                            sx={{
                                '&.MuiDataGrid-root .MuiDataGrid-container--top [role=row], &.MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]': {
                                    backgroundColor: 'rgba(203, 166, 96, 0.08)',
                                },
                                '& .MuiDataGrid-row': {
                                    borderBottom: 'none',
                                    transitionDuration: '0.3s',
                                    cursor: 'pointer',
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: '#ffffff'
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: '#ffffff'
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                    }
                                },
                                '& .MuiDataGrid-filler': {
                                    display: 'none',
                                }
                            }}
                        />
                    </Box>
                </Card>
                <InspectionDialog
                    open={dialogOpen}
                    handleClose={handleCloseDialog}
                    rowData={selectedRow}
                />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </Layout>
    )
}

export default QADetails;